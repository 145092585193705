import type { ComponentType } from "react"

export function withLazyLoading(Component): ComponentType {
    return (props) => {
        const modifiedProps = {
            ...props,
            defaultProps: {
                ...props.defaultProps,
                loading: "lazy",
            },
        }

        return <Component {...modifiedProps} />
    }
}
